import moment from 'moment'
function trunkTypeFilter(params) {
	const trunkType = {
		'1': '平台',
		'2': '工厂端',
		'4': '品牌商',
		'8': '经销商',
		'16': '门店',
		'32': '顾客端',
	}
	return trunkType[params]
}
function unlockTypeFilter(params) {
	const unlockType = {
		1: '支付解锁',
		2: '余额解锁',
		3: '套盒解锁',
		0: '无锁使用',
	}
	return unlockType[params]
}
function deviceStatusFilter(params) {
	const deviceStatusList = {
		1: '设备初始',
		2: '出厂测试',
		3: '激活申请',
		4: '使用中',
		5: '激活驳回',
		6: '远程锁定',
		8: '设备重置',
	}
	return deviceStatusList[params]
}
function dateFormat(value, format = 'YYYY-MM-DD  HH:mm:ss') {
	const date = value
	if (!date) {
		return '-'
	}
	return moment(date).format(format)
}
function idFormat(value, objMap, code = '') {
	console.log(value)
	if (!value) {
		return '-'
	}
	if (!code) {
		const arr = String(value)
			.split(',')
			.map((item) => {
				return objMap[item] && objMap[item].useItemName
			})
		return arr.join(',')
	}
	const arr = String(value)
		.split(',')
		.map((item) => {
			return (
				objMap[code] &&
				objMap[code][item] &&
				objMap[code][item].useItemName
			)
		})
	return arr.join(',')
}
export default {
	trunkTypeFilter,
	dateFormat,
	unlockTypeFilter,
	idFormat,
	deviceStatusFilter,
}
