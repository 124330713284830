export default {
    props: {
        title: {
            type: String,
        },
    },
    data() {
        return {
            titleName: this.title,
        }
    },
    methods: {
        init() {},
        back() {
            console.log('back')
            this.$emit('backMessage')
        },
    },
}
