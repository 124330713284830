var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "navbar" }, [
    _c(
      "div",
      { staticClass: "breadcrumb" },
      [
        _c("hamburger", {
          staticClass: "hamburger-container",
          attrs: { "is-active": _vm.sidebar.opened },
          on: { toggleClick: _vm.toggleSideBar },
        }),
        _c("breadcrumb", { staticClass: "breadcrumb-container" }),
        _c(
          "div",
          { staticClass: "right-menu" },
          [
            _c(
              "el-dropdown",
              { staticClass: "avatar-container", attrs: { trigger: "click" } },
              [
                _c(
                  "div",
                  { staticClass: "avatar-wrapper" },
                  [
                    _c("el-avatar", {
                      attrs: { shape: "square", src: _vm.avatar },
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.name))]),
                    _c("i", { staticClass: "el-icon-caret-bottom" }),
                  ],
                  1
                ),
                _c(
                  "el-dropdown-menu",
                  {
                    staticClass: "user-dropdown",
                    attrs: { slot: "dropdown" },
                    slot: "dropdown",
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" } },
                      [_c("el-dropdown-item", [_vm._v(" 回到首页 ")])],
                      1
                    ),
                    _c(
                      "el-dropdown-item",
                      {
                        attrs: { divided: "" },
                        nativeOn: {
                          click: function ($event) {
                            return _vm.signout($event)
                          },
                        },
                      },
                      [
                        _c("span", { staticStyle: { display: "block" } }, [
                          _vm._v("退出登录"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "menu-tab-box" },
      [
        _c(
          "el-tabs",
          {
            attrs: { type: "card", closable: _vm.menuTabs.length !== 1 },
            on: { "tab-remove": _vm.handleClose, "tab-click": _vm.tabClick },
            model: {
              value: _vm.activeMenuTab,
              callback: function ($$v) {
                _vm.activeMenuTab = $$v
              },
              expression: "activeMenuTab",
            },
          },
          _vm._l(_vm.menuTabs, function (menu) {
            return _c("el-tab-pane", {
              key: menu.path,
              attrs: { label: menu.title, name: menu.path },
            })
          }),
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }