import config from '@/static/config'
const customer = {
	storeMemberPage: {
		name: ' 顾客列表',
		type: 'get',
		path: config.gfUrl + 'store/member/page',
	},
	storeMemberReport: {
		name: ' 顾客检测报告list',
		type: 'get',
		path: config.gfUrl + 'store/member/report',
	},
	storeMemberReportInfo: {
		name: ' 顾客检测报告详情',
		type: 'get',
		path: config.gfUrl + 'store/member/report/info',
	},
}
export default customer
