const getters = {
	sidebar: (state) => state.app.sidebar,
	device: (state) => state.app.device,
	token: (state) => state.user.manager_token,
	avatar: (state) => state.user.avatar,
	name: (state) => state.user.name,
	companyId: (state) => {
		return state.user.companyId
	},
	trunkId: (state) => state.user.trunkId,
	trunkType: (state) => state.user.trunkType,
	loginType: (state) => state.user.loginType,
	leftMenuList: (state) => state.user.leftMenuList,
	init_permission: (state) => state.app.init_permission,
	menuTabs: (state) => state.user.menuTabs,
	activeMenuTab: (state) => state.user.activeMenuTab,
}
export default getters
