export default {
	props: {
		title: {
			type: String,
		},
		columns: {
			type: Array,
		},
		api: {
			type: Array,
		},
		selecttype: {
			type: String,
			default: 'radio',
		},
		modelback: {
			type: Function,
		},
		modeltype: {
			type: String,
		},
		modelparam: {
			type: Object,
			default: () => {
				return {}
			},
		},
		selectData: {
			type: [String, Number, Array],
			default: () => {
				return []
			},
		},
		selectDataKey: {
			type: String,
		},
		selectDataName: {
			type: String,
		},
		beforeHandleClose: {
			type: Function,
		},
		showSearchBox: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		let obj = {}

		console.log(this.selectDataKey, this.selectDataName)

		this.selectData.map((item) => {
			let tempObj = {}
			this.columns.forEach((column) => {
				tempObj[column.mapKey || column.prop] =
					item[column.mapKey || column.prop]
			})
			console.log(tempObj)
			obj[String(item[this.selectDataKey])] = tempObj
		})
		console.log(this.selectData, obj, this.columns)
		return {
			tableData: [],
			dialogVisible: true,
			tableLoading: true,
			selectList: this.selectData || [],
			selectMap: obj,
			radio: '',
			pageSize: 10,
			totalItem: 0,
			pagination: true,
			currentPage: 1,
			totalPage: 0,
			checkType: '',
			formSearch: {
				keyword: '',
			},
			list: Object.values(obj),
		}
	},
	methods: {
		indexMethod(index) {
			return index + 1 + (this.currentPage - 1) * this.pageSize
		},
		init() {
			this.radio = ''
			this.dialogVisible = true
			this.tableLoading = true
			const params = {
				pageIndex: this.currentPage,
				pageSize: this.pageSize,
			}
			this.$nextTick(() => {
				this.checkType = this.selecttype
			})
			this.$api[this.api[0]]
				[this.api[1]]({
					...this.modelparam,
					...params,
					...this.formSearch,
				})
				.then((res) => {
					let data = []
					if (res.traceId) {
						data = res.data
					} else {
						data = res
					}

					if (data.list) {
						console.log(data)

						this.tableData = data.list.map((item) => {
							item.check = false
							return { ...item }
						})
						this.pageSize = data.pageSize
						this.totalItem = data.totalItem
						this.pagination = true
						this.currentPage = data.currentPage
						this.totalPage = data.totalPage
						console.log(data)
					} else {
						this.tableData = data.map((item) => {
							item.check = false
							return { ...item }
						})

						this.pagination = false
					}
					console.log(this.tableData)
					const keys = Object.keys(this.selectMap)
					if (keys.length > 0) {
						this.tableData.forEach((item) => {
							if (
								keys.indexOf(
									String(item[this.columns[0].prop])
								) > -1
							) {
								this.$nextTick(() => {
									this.$refs.table.toggleRowSelection(
										item,
										true
									)
								})
							}
						})
					}

					this.tableLoading = false
				})
				.catch(() => {
					this.tableLoading = false
				})
		},
		// 页面数据数量变换
		handleSizeChange(val) {
			this.pageSize = val
			this.init()
		},
		// 当前页码变换
		handleCurrentChange(val) {
			this.currentPage = val
			this.init()
		},
		// 查询
		search() {
			this.init()
		},
		cancel() {
			this.modelback('cancel')
			this.dialogVisible = false
		},
		handleSure() {
			if (this.beforeHandleClose) {
				this.beforeHandleClose(this.handleCloseNotRequired)
			} else {
				this.handleClose()
			}
		},
		// 传递选中的数据
		handleClose(action) {
			if (action === 'cancel') {
				return
			}
			this.selectList = Object.values(this.selectMap)
			if (!this.selectList.length) {
				this.$message({
					message: `请${this.title}`,
					type: 'error',
					duration: 3 * 1000,
				})
				return
			}

			this.modelback(this.modeltype, this.selectList)
			this.dialogVisible = false
		},
		// 传递选中的数据
		handleCloseNotRequired() {
			this.selectList = Object.values(this.selectMap)

			this.modelback(this.modeltype, this.selectList)
			this.dialogVisible = false
		},
		selectCheckbox(selection, row) {
			this.rowClick(row)
		},
		// 选中列表点击删除
		handleTagClose(item) {
			const key = String(item[this.selectDataKey])
			const row = this.tableData.find((data) => {
				return key === String(data[this.columns[0].prop])
			})
			this.$delete(this.selectMap, key)
			let index = this.list.findIndex((item) => {
				return (
					item[this.columns[0].mapKey || this.columns[0].prop] == key
				)
			})
			this.list.splice(index, 1)
			this.$refs.table.toggleRowSelection(row, false)
		},
		// 点击行
		rowClick(row, column, event) {
			console.log('rowClick')
			if (this.selecttype === 'radio') {
				// TODO:判断条件有问题待处理
				this.tableData.forEach((item, index) => {
					let flag = true
					this.columns.forEach((column) => {
						if (item[column.prop] !== row[column.prop]) {
							flag = false
						}
					})
					if (flag) {
						item.check = true
						this.radio = item[this.columns[0].prop]
						let tempObj = {}
						this.selectMap = {}
						const key = String(row[this.columns[0].prop])
						this.columns.forEach((column) => {
							tempObj[column.mapKey || column.prop] =
								row[column.prop || column.prop]
						})
						this.$set(this.selectMap, key, tempObj)
					} else {
						item.check = false
					}
				})
			} else if (this.selecttype === 'checkbox') {
				console.log(this.list)
				const keys = Object.keys(this.selectMap)
				const key = String(row[this.columns[0].prop])
				const checkStatus = keys.includes(key)
				if (!checkStatus) {
					let tempObj = {}
					this.columns.forEach((column) => {
						tempObj[column.mapKey || column.prop] =
							row[column.prop || column.prop]
					})
					this.$set(this.selectMap, key, tempObj)
					this.list.push(tempObj)
				} else {
					this.$delete(this.selectMap, key)
					let index = this.list.findIndex((item) => {
						return (
							String(
								item[
									this.columns[0].mapKey ||
										this.columns[0].prop
								]
							) == key
						)
					})
					this.list.splice(index, 1)
				}

				console.log(this.selectMap)
				this.$refs.table.toggleRowSelection(row, !checkStatus)
			}
		},
		handleSelectAll(selection) {
			this.tableData.forEach((row) => {
				this.rowClick(row)
			})
		},
		cellInput(row, columnKey) {
			console.log(row)
			console.log(columnKey)
			const key = String(row[this.columns[0].prop])
			this.selectMap[key] &&
				(this.selectMap[key][columnKey] = row[columnKey])
			console.log(this.selectMap)
		},
	},
}
