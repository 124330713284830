const config = {
	baseUrl: process.env.VUE_APP_BASE_API,
	baseUrls: process.env.VUE_APP_YIYUAN_API,
	resUrl: process.env.VUE_APP_RES_PRD_API,
	account: process.env.VUE_APP_ACCOUNT_API,
	sseUrl: process.env.VUE_APP_SSE_API,
	imageURL: 'https://sin-pm.oss-cn-beijing.aliyuncs.com/img/manager/',
	addressURL: process.env.VUE_APP_ADDRESS_API,
	// 郭锋本地
	gfUrl: process.env.VUE_APP_BASE_API,
	lwUrl: process.env.VUE_APP_BASE_API,
}
export default config
