import router from './router'
import store from './store'
import { Message } from 'element-ui'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css' // progress bar style
import getPageTitle from '@/utils/get-page-title'
import api from './api'
import { resetRouter, dynamicRoutes } from '@/router'
import { routerControl, isInclude, havaPermission } from 'router-control'
NProgress.configure({ showSpinner: false }) // NProgress Configuration

const whiteList = ['/login', '/resetPassword'] // no redirect whitelist
router.beforeEach(async (to, from, next) => {
    // start progress bar
    NProgress.start()

    // set page title
    document.title = getPageTitle(to.meta.title)

    // determine whether the user has logged in
    const hasToken = store.getters.token
    if (hasToken) {
        api.user
            .accountPermission()
            .then((res) => {
                let permissionListMap = {}
                res.forEach((item) => {
                    permissionListMap[item.applicationCode] =
                        item.resourceMenuList
                })
                localStorage.setItem(
                    'permission_list',
                    JSON.stringify(permissionListMap)
                )
            })
            .catch((error) => {
                reject(error)
            })
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            next()
            NProgress.done()
        } else if (to.path === '/') {
            console.log('to.paht', to.path)
            console.log(
                'store.getters.leftMenuList[0]',
                store.getters.leftMenuList[0]
            )
            next({ ...store.getters.leftMenuList[0], replace: true })
            NProgress.done()
        } else {
            const hasGetUserPermission = store.getters.init_permission
            console.log(
                '-------------------------------------------------路由守卫beforeEach',
                hasGetUserPermission
            )
            if (!hasGetUserPermission) {
                routerControl(
                    to,
                    next,
                    api,
                    store,
                    dynamicRoutes,
                    router,
                    Message,
                    NProgress,
                    1
                )
            } else {
                console.log('dynamicRoutes', dynamicRoutes)
                console.log('to', to)
                const arr = store.getters.menuTabs
                const isHasMenuTab = arr.some((menu) => {
                    return menu.name === to.name
                })
                if (isHasMenuTab) {
                    arr.forEach((menu) => {
                        if (menu.name === to.name) {
                            menu.activeRouter = true
                        } else {
                            menu.activeRouter = false
                        }
                    })
                } else {
                    arr.forEach((item) => {
                        item.activeRouter = false
                    })
                    arr.push({
                        title: to.meta.title,
                        path: to.path,
                        params: to.params,
                        name: to.name,
                        activeRouter: true,
                    })
                }
                if (isInclude(dynamicRoutes, to)) {
                    if (havaPermission(store.getters.leftMenuList, to)) {
                        store.commit('user/SET_MENU_TABS', [...arr])
                        store.commit('user/SET_ACTIVE_MENU_TAB', to.path)
                        next()
                    } else {
                        Message.warning('暂无权限访问')
                        next(false)
                        NProgress.done()
                    }
                } else {
                    Message.warning('页面不存在')
                    next(false)
                    NProgress.done()
                }
                // console.log('=====================================', to)
                // try {
                // 	// get user info
                // 	// await store.dispatch('user/getInfo')

                // 	next()
                // } catch (error) {
                // 	// remove token and go to login page to re-login
                // 	await store.dispatch('user/resetToken')
                // 	Message.error(error || 'Has Error')
                // 	next(`/login?redirect=${to.path}`)
                // 	NProgress.done()
                // }
            }
        }
    } else {
        /* has no token*/

        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            console.log('无token时，路由跳转')

            next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            console.log('测试')
            next(`/login?redirect=${to.path}`)
            NProgress.done()
        }
    }
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})
