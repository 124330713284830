const configuration = {
	wechatConfigiList: {
		name: '小程序配置列表',
		type: 'get',
		path: '/wechat/config/page',
	},
	wechatConfigAdd: {
		name: '小程序配置新增',
		type: 'post',
		path: '/wechat/config/add',
	},
	wechatConfigUpdate: {
		name: '小程序配置修改',
		type: 'put',
		path: '/wechat/config/update',
	},
	wechatConfigStatus: {
		name: '小程序禁用',
		type: 'post',
		path: '/wechat/config/status',
		paramType: 'URL',
	},
	wechatConfigCode: {
		name: '小程序二维码',
		type: 'get',
		path: '/wechat/config/code',
	},
	wechatConfigDelete: {
		name: '删除小程序配置',
		type: 'delete',
		path: '/wechat/config/delete',
		paramType: 'URL',
	},
	wechatConfigApp: {
		name: '查询应用类型',
		type: 'get',
		path: '/wechat/config/app',
	},
	notifyTrunkList: {
		name: '接收对象下拉框查询',
		type: 'get',
		path: '/notify/model/trunk/list',
	},
	notifyModelPage: {
		name: '模版列表分页查询',
		type: 'get',
		path: '/notify/model/page',
	},
	notifyModelTypeList: {
		name: '业务类型下拉框查询',
		type: 'get',
		path: 'notify/model/type/list',
	},
	notifyModelTransferMethodList: {
		name: '推送方式下拉框查询',
		type: 'get',
		path: '/notify/model/transferMethod/list',
	},
	notifyModelAdd: {
		name: '模版新增',
		type: 'post',
		path: '/notify/model',
	},
	notifyModelUpdate: {
		name: '模版修改',
		type: 'put',
		path: '/notify/model',
	},
	notifyModelDelete: {
		name: '模版删除',
		type: 'delete',
		path: '/notify/model',
		paramType: 'URL',
	},
	processSave: {
		name: '新增或者修改流程',
		type: 'post',
		path: '/process/save',
	},
	processList: {
		name: '查询流程列表',
		type: 'get',
		path: '/process/list',
	},
	processItemSave: {
		name: '新增或者修改流程项',
		type: 'post',
		path: '/process/item/save',
	},
	processDelete: {
		name: '删除流程',
		type: 'delete',
		path: '/process/del',
		paramType: 'URL',
	},
	processItemDelete: {
		name: '删除流程项',
		type: 'delete',
		path: '/process/item/del',
		paramType: 'URL',
	},
	propertyMappingSave: {
		name: '保存型号数据映射',
		type: 'post',
		path: '/property/mapping/save',
	},
	propertyOptionSave: {
		name: ' 添加属性选项',
		type: 'post',
		path: '/property/option/save',
	},
	propertyOptionDelete: {
		name: ' 删除属性选项',
		type: 'delete',
		path: '/property/option/del',
		paramType: 'URL',
	},
	propertyOptionList: {
		name: ' 查询属性选项列表',
		type: 'get',
		path: '/property/option/list',
	},
	propertyMappingPage: {
		name: ' 查询数据模型配置列表',
		type: 'get',
		path: '/property/mapping/page',
	},
	propertyMappingList: {
		name: ' 查询数据模型配置详情',
		type: 'get',
		path: '/property/mapping/list',
	},
	dictClassList: {
		name: ' 查询字典分类列表',
		type: 'get',
		path: '/dict/class/list',
	},
	dictClassSave: {
		name: '保存字典分类',
		type: 'post',
		path: '/dict/class/save',
	},
	dictClassDelete: {
		name: '删除字典分类',
		type: 'delete',
		path: '/dict/class/del',
		paramType: 'URL',
	},
	dictItemList: {
		name: '查询字典项列表',
		type: 'get',
		path: '/dict/item/map',
	},
	dictItemSave: {
		name: '保存字典项',
		type: 'post',
		path: '/dict/item/save',
	},
	dictItemDelete: {
		name: '删除字典项',
		type: 'delete',
		path: '/dict/item/del',
		paramType: 'URL',
	},
	dictItemMap: {
		name: '查询字典项列表',
		type: 'get',
		path: '/dict/item/map',
	},
	roleModelList: {
		name: '查询角色模板列表',
		type: 'get',
		path: '/role/model/list',
	},
	roleModelCateList: {
		name: '查询角色类型列表',
		type: 'get',
		path: '/role/model/cate/list',
	},
	roleModelCateSave: {
		name: '新增角色模板',
		type: 'post',
		path: '/role/model/cate',
	},
	roleModelCateUpdate: {
		name: '编辑角色模板',
		type: 'put',
		path: '/role/model/cate',
	},
	roleModelCateDelete: {
		name: '删除角色模板',
		type: 'delete',
		path: '/role/model/cate',
		paramType: 'URL',
	},
	roleModelDetail: {
		name: '查询角色模板详情',
		type: 'get',
		path: '/role/model/get',
	},
	getAdPage: {
		name: '',
		type: 'get',
		path: '/model/advert/page',
	},
}

export default configuration
