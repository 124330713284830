var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "added-page" }, [
    _c(
      "div",
      { staticClass: "header" },
      [
        _c("el-page-header", {
          attrs: { content: _vm.titleName },
          on: { back: _vm.back },
        }),
      ],
      1
    ),
    _c("div", { staticClass: "content" }, [_vm._t("content")], 2),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }