<template>
  <div class="login-container">
    <div class="login-div1">
      <div class="close" @click="close">
        <i class="el-icon-close"></i>
      </div>
      <el-row>
        <el-col :span="24">
          <el-card class="login-form" shadow="never">
            <!-- <p class="login-title">小鹦管家SAAS运营管理平台</p> -->
            <el-tabs v-model="activeName">
              <el-tab-pane label="账号登录" name="first">
                <el-form ref="loginForm" :model="loginForm" :rules="loginRules" auto-complete="on"
                  label-position="left">
                  <el-form-item prop="username">
                    <el-input v-model="loginForm.username" placeholder="用户名" type="text"
                      tabindex="1" auto-complete="on" prefix-icon="el-icon-user" />
                  </el-form-item>

                  <el-form-item prop="password">
                    <el-input ref="password" :key="passwordType" v-model="loginForm.password"
                      :type="passwordType" placeholder="密码" tabindex="2" auto-complete="on"
                      prefix-icon="el-icon-key" />
                    <span class="show-pwd" @click="showPwd">
                      <svg-icon :icon-class="
                          passwordType === 'password' ? 'eye' : 'eye-open'
                        " />
                    </span>
                  </el-form-item>

                  <div>
                    <Verify v-if="isVerify" :type="3" explain="按住滑块滑动并拖动到最右边"
                      :bar-size="{ height: '50px',width:'100%' }" @success="alert('success')"
                      @error="alert('error')" />
                  </div>

                  <el-button :loading="loading" type="primary" class="login-bth"
                    @click.native.prevent="handleLogin">登录</el-button>
                </el-form>
              </el-tab-pane>
              <el-tab-pane label="短信登录" name="second">
                <el-form ref="codeLoginForm" :model="codeLoginForm" :rules="codeRules"
                  auto-complete="on" label-position="left" class="noteLogin">
                  <el-form-item prop="mobile">
                    <el-input v-model="codeLoginForm.mobile" placeholder="请输入手机号" type="text"
                      tabindex="1" prefix-icon="el-icon-mobile-phone" auto-complete="on" />
                  </el-form-item>

                  <el-form-item prop="noteCode" class="pr">
                    <el-input v-model="codeLoginForm.noteCode" placeholder="请输入短信验证码"
                      name="noteCode" tabindex="2" prefix-icon="el-icon-message"
                      auto-complete="on" />
                    <el-button class="code-btn" :disabled="!show" @click="getCode()">
                      <span v-show="show">发送验证码</span>
                      <span v-show="!show" class="count">{{ count }} s 后重新获取</span>
                    </el-button>
                  </el-form-item>
                  <el-button :loading="codeLoading" type="primary" class="login-bth"
                    @click.native.prevent="handleCodeLogin">登录</el-button>
                </el-form>
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Verify from 'vue2-verify'

import { getCode } from '@/api/user'
import { setToken } from '@/utils/auth'
import { mapActions } from 'vuex'
const TIME_COUNT = 60
export default {
    name: 'Login',
    components: {
        Verify,
    },

    data() {
        // 设置手机号的验证规则
        const phoneRules = (rule, value, callback) => {
            if (!value) {
                callback(new Error('请输入手机号'))
            } else {
                const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/
                if (reg.test(value)) {
                    callback()
                } else {
                    return callback(new Error('请输入正确的电话'))
                }
            }
        }
        return {
            loginForm: {
                username: '',
                password: '',
            },
            codeLoginForm: {
                mobile: '',
                noteCode: '',
            },
            loginRules: {
                username: [
                    {
                        required: true,
                        message: '请输入用户名',
                        trigger: 'blur',
                    },
                ],
                password: [
                    {
                        required: true,
                        message: '请输入用密码',
                        trigger: 'blur',
                    },
                ],
            },
            codeRules: {
                mobile: [
                    { required: true, validator: phoneRules, trigger: 'blur' },
                ],
                noteCode: [
                    {
                        required: true,
                        message: '请输入短信验证码',
                        trigger: 'blur',
                    },
                ],
            },
            loading: false,
            codeLoading: false,
            passwordType: 'password',
            redirect: undefined,
            activeName: 'first',
            checked: false,
            show: true,
            count: '',
            timer: null,
            slide: false,
            isVerify: true,
        }
    },
    watch: {
        $route: {
            handler: function (route) {
                this.redirect = route.query && route.query.redirect
            },
            immediate: true,
        },
    },
    mounted() {
        const that = this
        window.onresize = () => {
            return (() => {
                that.isVerify = false
                that.$nextTick(() => {
                    that.isVerify = true
                })
            })()
        }
    },
    methods: {
        ...mapActions('app', ['setModelLogin']),
        ...mapActions('user', ['login']),
        alert(text) {
            if (text === 'success') {
                this.slide = true
            } else {
                this.slide = false
            }
        },
        showPwd() {
            if (this.passwordType === 'password') {
                this.passwordType = ''
            } else {
                this.passwordType = 'password'
            }
            this.$nextTick(() => {
                this.$refs.password.focus()
            })
        },
        handleLogin() {
            if (!this.slide) {
                this.$message({
                    message: '请向右滑动完成验证!',
                    type: 'warning',
                })
                return
            }
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.$store.commit('user/SET_LOGIN_TYPE', '0')
                    this.loading = true
                    this.login(this.loginForm).finally(() => {
                        this.loading = false
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        close() {
            this.setModelLogin(false)
        },
        handleCodeLogin() {
            this.$refs.codeLoginForm.validate((valid) => {
                if (valid) {
                    this.codeLoading = true
                    const data = {
                        phone: this.codeLoginForm.mobile,
                        verifyCode: this.codeLoginForm.noteCode,
                    }
                    this.$store.commit('user/SET_LOGIN_TYPE', '1')
                    this.login(data).finally(() => {
                        this.codeLoading = false
                    })
                }
            })
        },
        resetPassword() {
            this.$router.push({ path: '/resetPassword' })
        },
        getCode(formData) {
            // 发送短信

            this.$refs.codeLoginForm.validateField('mobile', (valid) => {
                if (!valid) {
                    getCode(this.codeLoginForm.mobile, this).then((res) => {
                        this.$message({
                            message: '短信发送成功请注意查收!',
                            type: 'success',
                        })
                    })

                    // 验证码倒计时
                    if (!this.timer) {
                        this.count = TIME_COUNT
                        this.show = false
                        this.timer = setInterval(() => {
                            if (this.count > 0 && this.count <= TIME_COUNT) {
                                this.count--
                            } else {
                                this.show = true
                                clearInterval(this.timer)
                                this.timer = null
                            }
                        }, 1000)
                    }
                }
            })
        },
    },
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
</style>

<style lang="scss">
.login-container {
    .close {
        position: absolute;
        right: 5px;
        top: 5px;
        z-index: 9999;
        cursor: pointer;
    }
    .el-tabs__item.is-active {
        color: #409eff !important;
    }

    .verify-btn {
        display: none;
    }

    .el-tabs__nav-wrap::after {
        background-color: #ffffff !important;
    }

    .el-input__inner {
        border-top: 0px;
        border-left: 0px;
        border-right: 0px;
        height: 45px;
        line-height: 45px;
    }
    .el-tabs__item {
        font-size: 15px;
        font-weight: bold;
        color: #a0a0a0;
    }

    .el-form-item__content {
        line-height: 40px;
    }
    .el-form-item__error {
        position: absolute;
        top: 100%;
        right: 0 !important;
        left: inherit;
    }
    .el-checkbox__label {
        font-size: 12px;
    }
    .el-button {
        font-size: 12px;
    }

    .verify-bar-area {
        background: #ddd;
    }
    .verify-msg {
        font-size: 12px;
    }
    .verify-left-bar {
        background-color: rgb(92, 184, 92);
        .verify-msg {
            color: #fff;
        }
    }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
    .noteLogin {
        .el-form-item {
            margin-top: 30px;
        }
        .login-bth {
            margin-top: 40px;
        }
    }
    .login-bth {
        width: 100%;
        margin-bottom: 30px;
        font-size: 16px;
        font-weight: bold;
        border-radius: 30px;
        margin-top: 20px;
    }

    .svg-container {
        padding: 6px 5px 6px 15px;
        // color: $dark_gray;
        vertical-align: middle;
        width: 30px;
        display: inline-block;
    }

    .title-container {
        position: relative;

        .title {
            font-size: 26px;
            // color: $light_gray;
            margin: 0px auto 40px auto;
            text-align: center;
            font-weight: bold;
        }
    }

    .show-pwd {
        position: absolute;
        right: 10px;
        top: 7px;
        font-size: 16px;
        // color: $dark_gray;
        cursor: pointer;
        user-select: none;
    }

    .login-div {
        height: 30px;
        line-height: 30px;
        button {
            float: right;
        }
    }
    .code-btn {
        width: 110px;
        height: 34px;
        position: absolute;
        top: 3px;
        right: 5px;
        z-index: 222;
        color: #409eff;
        font-size: 12px;
        border: none;
        //    border-left: 1px solid #bababa;
        padding-left: 16px;
        background-color: #fff;
    }
    .login-title {
        font-size: 32px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #404040;
        letter-spacing: 3px;
    }

    @media screen and (max-width: 1400px) {
        .login-title {
            font-size: 28px;
        }
    }

    .el-card {
        border: 0px;
    }
    .login-img {
        width: 90%;
        height: 70vh;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 25%;
        padding: 10px 30px;
        img {
            width: 100%;
        }
    }
}
</style>
