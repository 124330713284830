<template>
  <div id="app">
    <router-view />
    <div class="mark" v-if="modelLogin">
      <div class="login">
        <login></login>
      </div>
    </div>
  </div>
</template>

<script>
import Login from '@/views/login/model/index.vue'
import { mapState, mapActions } from 'vuex'
import store from './store'
export default {
    components: {
        Login,
    },
    name: 'App',
    computed: {
        ...mapState('app', ['modelLogin']),
    },
    mounted() {
        window.addEventListener('beforeunload', (e) => {
            // console.log(
            //     '-----------------------------------------------------------------------,页面刷新开始'
            // )
            store.commit('user/SET_INIT_PERMISSION', false)
        })
    },
}
</script>
<style lang="scss" scoped>
#app {
    position: relative;
    .mark {
        position: absolute;
        z-index: 9999;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #000000, $alpha: 0.3);
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .login {
            position: absolute;
            width: 25%;
        }
    }
}
</style>