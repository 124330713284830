import Vue from 'vue'
import Router from 'vue-router'
import store from '../store/index'

Vue.use(Router)
const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch((err) => err)
}
/* Layout */
import Layout from '@/layout'

/**
 * Note: sub-menu only appear when route children.length >= 1
 * Detail see: https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 *
 * hidden: true                   if set true, item will not show in the sidebar(default is false)
 * alwaysShow: true               if set true, will always show the root menu
 *                                if not set alwaysShow, when item has more than one children route,
 *                                it will becomes nested mode, otherwise not show the root menu
 * redirect: noRedirect           if set noRedirect will no redirect in the breadcrumb
 * name:'router-name'             the name is used by <keep-alive> (must set!!!)
 * meta : {
    roles: ['admin','editor']    control the page roles (you can set multiple roles)
    title: 'title'               the name show in sidebar and breadcrumb (recommend set)
    icon: 'svg-name'/'el-icon-x' the icon show in the sidebar
    breadcrumb: false            if set false, the item will hidden in breadcrumb(default is true)
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
 */

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [
	{
		path: '/login',
		component: () => import('@/views/login/index'),
		name: 'login',
		meta: { title: '登录' },
		hidden: true,
	},
	{
		path: '/resetPassword',
		name: 'ResetPassword',
		component: () => import('@/views/login/resetPassword'),
		meta: { title: 'ResetPassword' },
		hidden: true,
	},

	// 404 page must be placed at the end !!!
	{
		path: '/404',
		name: '404',
		hidden: true,
		component: () => import('@/views/404.vue'),
	},
]
export const dynamicRoutes = [
	{
		path: '/home',
		component: Layout,
		name: 'home',
		redirect: '/home/dashboard',
		children: [
			{
				path: 'dashboard',
				name: 'Dashboard',
				component: () => import('@/views/dashboard/index.vue'),
				meta: { title: '首页', icon: 'dashboard' },
			},
		],
	},
	{
		path: '/clientManagement',
		component: Layout,
		name: 'clientManagement',
		redirect: '/clientManagement/clientlist',
		meta: { title: '客户管理', icon: 'el-icon-s-help' },
		children: [
			{
				path: 'clientlist',
				name: 'clientlist',
				component: () => import('@/views/clientManagement/clientList'),
				meta: { title: '客户列表' },
			},
			{
				path: 'intentionclient',
				name: 'intentionclient',
				component: () =>
					import('@/views/clientManagement/intentionClient'),
				meta: { title: '意向客户' },
			},
		],
	},
	{
		path: '/customerManagement',
		component: Layout,
		name: 'customerManagement',
		redirect: '/customerManagement/customerList',
		meta: { title: '用户管理', icon: 'el-icon-s-help' },
		children: [
			{
				path: 'customerList',
				name: 'customerList',
				component: () =>
					import(
						'@/views/customerManagement/customer-list/customer-list.vue'
					),
				meta: { title: '用户列表' },
			},
		],
	},
	{
		path: '/useManagement',
		component: Layout,
		name: 'useManagement',
		redirect: '/useManagement/uselist',
		meta: { title: '应用管理', icon: 'form' },
		alwaysShow: true,
		children: [
			{
				path: 'uselist',
				name: 'uselist',
				component: () => import('@/views/useManagement/useList'),
				meta: { title: '应用列表' },
			},
		],
	},
	{
		path: '/internet',
		component: Layout,
		name: 'internet',
		redirect: '/internet/equipmentManagement',
		meta: { title: '设备管理', icon: 'el-icon-s-help' },
		children: [
			{
				path: 'equipmentManagement',
				name: 'equipmentManagement',
				component: () =>
					import('@/views/internetManagement/equipmentManagement'),
				meta: { title: '设备型号管理' },
			},
			{
				path: 'equipmentCommand',
				name: 'equipmentCommand',
				component: () =>
					import('@/views/internetManagement/equipmentCommand'),
				meta: { title: '远程指令' },
			},

			{
				path: 'equipmentList',
				name: 'equipmentList',
				component: () =>
					import('@/views/internetManagement/equipmentList'),
				meta: { title: '设备列表' },
			},
			{
				path: 'equipmentUse',
				name: 'equipmentUse',
				component: () =>
					import(
						'@/views/internetManagement/equipmentUse/equipmentUse.vue'
					),
				meta: { title: '设备使用记录' },
			},
			{
				path: 'classify',
				name: 'classify',
				component: () =>
					import('@/views/internetManagement/equipmentClassify'),
				meta: { title: '设备分类' },
			},
			// {
			//     path: 'warning',
			//     name: 'Warning',
			//     component: () => import('@/views/internetManagement/warnList'),
			//     meta: { title: '设备预警' },
			// },
			{
				path: 'equipmentAttribute',
				name: 'equipmentAttribute',
				component: () =>
					import(
						'@/views/internetManagement/equipmentAttribute/equipmentAttribute.vue'
					),
				meta: { title: '设备属性配置' },
			},
			{
				path: 'equipmentStatistics',
				name: 'equipmentStatistics',
				component: () =>
					import(
						'@/views/internetManagement/equipmentStatistics/equipmentStatistics.vue'
					),
				meta: { title: '设备统计配置' },
				hidden: true,
			},

			{
				path: 'equipmentAPKUpgrade',
				name: 'equipmentAPKUpgrade',
				component: () =>
					import(
						'@/views/internetManagement/equipmentAPKUpgrade/equipmentAPKUpgrade.vue'
					),
				meta: { title: '设备应用升级' },
			},
		],
	},
	{
		path: '/commodityClassify',
		component: Layout,
		name: 'commodityClassify',
		meta: { title: '商品分类', icon: 'form' },
		alwaysShow: true,
		redirect: '/commodityClassify/classifyManagement',
		children: [
			{
				path: 'classifyManagement',
				name: 'classifyManagement',
				component: () =>
					import('@/views/commodityClassify/classifyManagement'),
				meta: { title: '分类管理' },
			},
			{
				path: 'specificationManager',
				name: 'specificationManager',
				component: () =>
					import(
						'@/views/commodityClassify/specification-manager/specification-manager'
					),
				meta: { title: '规格管理' },
			},
		],
	},
	{
		path: '/staffManagement',
		component: Layout,
		name: 'staffManagement',
		redirect: '/staffManagement/personManagement',
		meta: { title: '员工管理', icon: 'el-icon-s-help' },
		children: [
			{
				path: 'personManagement',
				name: 'personManagement',
				component: () =>
					import('@/views/staffManagement/personManagement'),
				meta: { title: '员工列表' },
			},
			{
				path: 'jurisdictionManagement',
				name: 'jurisdictionManagement',
				component: () =>
					import('@/views/staffManagement/jurisdictionManagement'),
				meta: { title: '权限管理' },
			},
			{
				path: 'departmentManagement',
				name: 'departmentManagement',
				component: () =>
					import('@/views/staffManagement/departmentManagement'),
				meta: { title: '部门管理' },
			},
		],
	},
	{
		path: '/configuration',
		component: Layout,
		name: 'configuration',
		redirect: '/configuration/appletConfig',
		alwaysShow: true,
		meta: { title: '配置管理', icon: 'el-icon-setting' },
		children: [
			{
				path: 'appletConfig',
				name: 'appletConfig',
				component: () =>
					import(
						'@/views/configuration/appletConfig/appletConfig.vue'
					),
				meta: { title: '小程序配置' },
			},
			{
				path: 'messageTemplate',
				name: 'messageTemplate',
				component: () =>
					import(
						'@/views/configuration/messageTemplate/messageTemplate.vue'
					),
				meta: {
					title: '消息模板设置',
				},
			},
			{
				path: 'wisdom-hut',
				name: 'wisdom-hut',
				component: () =>
					import('@/views/configuration/wisdom-hut/wisdom-hut.vue'),
				meta: {
					title: '智慧小屋配置',
				},
			},
			{
				path: 'dictionaries',
				name: 'dictionaries',
				component: () =>
					import(
						'@/views/configuration/dictionaries/dictionaries.vue'
					),
				meta: {
					title: '字典配置',
				},
			},
			{
				path: 'adTemplate',
				name: 'adTemplate',
				component: () => import('@/views/adManage/adTemplate.vue'),
				meta: {
					title: '广告模板配置',
				},
			},
			{
				path: 'roleTemplate',
				name: 'roleTemplate',
				component: () =>
					import(
						'@/views/configuration/roleTemplate/roleTemplate.vue'
					),
				meta: {
					title: '角色模板配置',
				},
			},
		],
	},
	{
		path: '/notification',
		component: Layout,
		name: 'notification',
		redirect: '/notification/toDo',
		meta: { title: '消息通知', icon: 'el-icon-bell' },
		alwaysShow: true,
		children: [
			{
				path: 'toDo',
				name: 'toDo',
				component: () => import('@/views/notification/toDo/ToDo.vue'),
				meta: { title: '待办事项' },
			},
		],
	},
	{
		path: '/operate',
		component: Layout,
		name: 'operate',
		parentUrl: 'operate',
		redirect: '/operate/list',
		meta: { title: '运营', icon: 'el-icon-s-help' },
		children: [
			{
				path: 'operatelists',
				name: 'operatelists',
				component: () => import('@/views/operate/operateList'),
				meta: { title: '广告管理' },
			},
		],
	},
]
const createRouter = () =>
	new Router({
		// mode: 'history', // require service support
		scrollBehavior: () => ({ y: 0 }),
		routes: constantRoutes,
	})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
	const newRouter = createRouter()
	router.matcher = newRouter.matcher // reset router
}

export default router
