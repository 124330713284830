const files = require.context(".", false, /\.js$/);
let modules = {};
files.keys().forEach(key => {
  if (key === "./index.js") return;

  const item = files(key).default;
  const name = key.split(".")[1].split("/")[1];

  modules = { ...modules, [name]: { ...item } };
});

console.log(modules);
export default modules;
