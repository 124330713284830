import config from '@/static/config'

const application = {
	addButton: {
		name: ' 新增按钮',
		type: 'post',
		path: '/button/add',
	},
	updateButton: {
		name: ' 修改按钮',
		type: 'put',
		path: '/button/update',
	},
	deleteButton: {
		name: '删除按钮',
		type: 'delete',
		path: '/button/delete',
		paramType: 'URL',
	},
}
export default application
