import request from '@/api/request'
import config from '@/static/config'
// 账号登录
export function login(data) {
    return request({
        url: `/user/login`,
        method: 'post',
        data,
    })
}

// 获取登录短信
export function getCode(data, vm) {
    console.log(vm.trunkTypeList)
    return request({
        url: `${config.account}/user/generate/sms/login/verification?phone=${data}&trunkType=${vm.trunkTypeList[0].label}`,
        method: 'get',
    })
}

// 手机登录
export function loginCode(data) {
    return request({
        url: `${config.account}/login`,
        method: 'post',
        data,
    })
}

// 重置密码-发送验证码
export function getCodePassword(data, vm) {
    return request({
        url: `${config.account}/user/generate/sms/set/verification?phone=${data}&trunkType=${vm.trunkTypeList[0].label}`,
    })
}

// 重置密码-提交修改密码
export function uploadPass(data) {
    return request({
        url: `${config.account}/user/logout/set/phone?phone=${data.phone}&verifyCode=${data.verifyCode}&newPassword=${data.newPassword}&trunkType=${data.trunkType}`,
        method: 'put',
    })
}

// 18513378018

export function getInfo(token) {
    return request({
        url: '/vue-admin-template/user/info',
        method: 'get',
        params: { token },
    })
}

// 登出
export function userLogout() {
    return request({
        url: '/logout',
        method: 'POST',
    })
}

export function test() {
    return request({
        url: '/test/111',
        method: 'get',
    })
}

// 获取菜单数据
export function treeResource() {
    return request({
        url: '/user/user/tree/resource',
        method: 'GET',
    })
}

// 获取登录用户信息
export function userInfo(data) {
    return request({
        url: `/user/info`,
        method: 'GET',
    })
}
