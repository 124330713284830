import config from '@/static/config'
const commodity = {
	commodityCategoryList: {
		name: '查询商品分类列表',
		type: 'get',
		path: '/category/getList',
	},
	commodityCategoryAdd: {
		name: ' 新增商品分类',
		type: 'post',
		path: '/category/add',
	},
	commodityCategoryDelete: {
		name: ' 删除商品分类',
		type: 'delete',
		path: '/category/del',
	},
	commodityCategoryUpdate: {
		name: ' 修改商品分类',
		type: 'put',
		path: '/category/update ',
	},
	attrAdd: {
		name: '新增规格',
		type: 'post',
		path: '/attr/add',
	},
	attrPage: {
		name: '查询规格列表',
		type: 'get',
		path: '/attr/page',
	},
	attrDelete: {
		name: '删除规格',
		type: 'post',
		path: '/attr/delete',
		paramType: 'URL',
	},
}
export default commodity
