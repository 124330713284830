<template>
  <div class="navbar">
    <div class="breadcrumb">
      <hamburger :is-active="sidebar.opened" class="hamburger-container"
        @toggleClick="toggleSideBar" />

      <breadcrumb class="breadcrumb-container" />

      <div class="right-menu">
        <el-dropdown class="avatar-container" trigger="click">
          <div class="avatar-wrapper">
            <el-avatar shape="square" :src="avatar"></el-avatar>
            <span>{{name}}</span>
            <i class="el-icon-caret-bottom" />
          </div>
          <el-dropdown-menu slot="dropdown" class="user-dropdown">
            <router-link to="/">
              <el-dropdown-item>
                回到首页
              </el-dropdown-item>
            </router-link>
            <el-dropdown-item divided @click.native="signout">
              <span style="display:block;">退出登录</span>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <div class="menu-tab-box">
      <el-tabs v-model="activeMenuTab" type="card" :closable="menuTabs.length !== 1"
        @tab-remove="handleClose" @tab-click="tabClick">
        <el-tab-pane :key="menu.path" v-for="menu in menuTabs" :label="menu.title"
          :name="menu.path">
        </el-tab-pane>
      </el-tabs>
      <!-- <el-tag class="menu-tab-item" :effect="menu.activeRouter?'dark':'plain'" :key="menu.name"
        v-for="menu in menuTabs" closable :disable-transitions="false" @close="handleClose(menu)">
        {{menu.title}}
      </el-tag> -->
    </div>

  </div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Breadcrumb from '@/components/Breadcrumb'
import Hamburger from '@/components/Hamburger'
// import { getToken, setToken, removeToken } from '@/utils/auth'
// import { resetRouter } from '@/router'
// import { userLogout } from '@/api/user'
export default {
    components: {
        Breadcrumb,
        Hamburger,
    },
    computed: {
        ...mapGetters(['sidebar', 'avatar', 'name', 'menuTabs']),
        activeMenuTab: {
            get() {
                return this.$store.getters.activeMenuTab
            },
            set() {},
        },
    },
    methods: {
        ...mapActions('user', ['logout']),
        toggleSideBar() {
            this.$store.dispatch('app/toggleSideBar')
        },
        signout() {
            this.logout(this)
        },
        // 关闭页签
        handleClose(name) {
            if (this.menuTabs.length === 1) return
            if (this.activeMenuTab === name) {
                console.log(this.menuTabs)
                const arr = this.menuTabs.map((item) => {
                    return item
                })
                let index = arr.findIndex((item) => {
                    return item.path === name
                })
                arr.splice(index, 1)
                console.log(index)
                console.log(arr)
                index = index > 0 ? index : 1
                this.$router.push({ path: arr[index - 1].path })
                this.$store.commit('user/SET_MENU_TABS', [...arr])
            } else {
                console.log(this.menuTabs)
                const arr = this.menuTabs
                let index = arr.findIndex((item) => {
                    return item.path === name
                })
                arr.splice(index, 1)
                console.log(index)
                this.$store.commit('user/SET_MENU_TABS', [...arr])
            }
        },
        tabClick(tab) {
            console.log(tab)
            this.$router.push({ path: tab.name })
        },
    },
}
</script>

<style lang="scss" scoped>
.navbar {
    height: 100px;
    overflow: hidden;
    position: relative;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
    .menu-tab-box {
        width: 100%;
        height: 50px;
        .menu-tab-item {
            flex: 1;
            max-width: 100px;
            overflow: hidden;
            ::v-deep .el-icon-close {
                width: 23px;
            }
        }
    }
    .breadcrumb {
        height: 50px;
    }
    .hamburger-container {
        line-height: 46px;
        // height: 100%;
        float: left;
        cursor: pointer;
        transition: background 0.3s;
        -webkit-tap-highlight-color: transparent;

        &:hover {
            background: rgba(0, 0, 0, 0.025);
        }
    }

    .breadcrumb-container {
        float: left;
    }

    .right-menu {
        float: right;
        // height: 100%;
        // line-height: 50px;

        &:focus {
            outline: none;
        }

        .right-menu-item {
            display: inline-block;
            padding: 0 8px;
            height: 100%;
            font-size: 18px;
            color: #5a5e66;
            vertical-align: text-bottom;

            &.hover-effect {
                cursor: pointer;
                transition: background 0.3s;

                &:hover {
                    background: rgba(0, 0, 0, 0.025);
                }
            }
        }

        .avatar-container {
            margin-right: 30px;

            .avatar-wrapper {
                margin-top: 5px;
                position: relative;
                display: flex;
                align-items: center;
                .user-avatar {
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 10px;
                }

                .el-icon-caret-bottom {
                    cursor: pointer;
                    position: absolute;
                    right: -20px;
                    top: 25px;
                    font-size: 12px;
                }
            }
        }
    }
}
</style>
