import config from '@/static/config'
const role = {
	roleList: {
		name: ' 查询角色列表',
		type: 'get',
		path: '/role/list',
	},
	permissionList: {
		name: '查询权限列表',
		type: 'get',
		path: '/role/manager/permission',
	},
	selectRole: {
		name: '查询指定角色信息',
		type: 'get',
		path: '/role/select',
	},
	roleListSelect: {
		name: '角色列表下拉框查询',
		type: 'get',
		path: '/role/list/select',
	},
}
export default role
