import axios from 'axios'
import { Message } from 'element-ui'
// import { getToken, removeToken } from '@/utils/auth'
import config from '@/static/config'
// import client from "./modules/client";
import router from '@/router'
import store from '@/store'
import vm from '../main'
// create an axios instance
const service = axios.create({
    baseURL: config.baseUrl, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 10000, // request timeout
})
let tokenAbnormal = false
let userAbnormal = false
// request interceptor
service.interceptors.request.use(
    (config) => {
        // do something before request is sent
        // if (store.getters.token) {
        // let each request carry token
        // ['X-Token'] is a custom headers key
        // please modify it according to the actual situation

        config.headers['TERMINAL_TYPE'] = '5'
        config.headers['SYSTEM_TYPE'] = '1'
        config.headers['LOGIN_TYPE'] = store.getters.loginType
        if (store.getters.token) {
            config.headers['Authorization'] = `Bearer ${store.getters.token}`
        }
        return config
    },
    (error) => {
        // do something with request error
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */

    // 401 或 4000 ~ 4999 跳登录

    (response) => {
        if (response.status === 200) {
            const { code, data, msg } = response.data
            switch (code) {
                case '0':
                    if (data) {
                        return Promise.resolve(data)
                    } else {
                        return Promise.reject(data)
                    }
                    break
                case '401':
                    if (!tokenAbnormal) {
                        tokenAbnormal = true
                        // 弹出框
                        // 设置定时器，确保下次异常时弹出框正常弹出
                        // console.log(response)
                        if (response.config.url.includes('/logout')) {
                            return
                        }
                        setTimeout(() => {
                            tokenAbnormal = false
                            Message({
                                message: msg || 'Error',
                                type: 'error',
                                duration: 2 * 1000,
                            })
                            store.dispatch('app/setModelLogin', true)
                            // removeToken()
                        }, 1000)
                    }

                    break
                case '403':
                    Message({
                        message: '无权限访问',
                        type: 'error',
                        duration: 2 * 1000,
                    })
                    break
                case '4991':
                    if (!tokenAbnormal) {
                        tokenAbnormal = true
                        // 弹出框
                        // 设置定时器，确保下次异常时弹出框正常弹出
                        setTimeout(() => {
                            tokenAbnormal = false
                            Message({
                                message: msg || 'Error',
                                type: 'error',
                                duration: 2 * 1000,
                            })
                            store.dispatch('app/setModelLogin', true)
                            // removeToken()
                        }, 1000)
                    }

                    break
                case '4999':
                    if (!userAbnormal) {
                        userAbnormal = true
                        // 弹出框
                        // 设置定时器，确保下次异常时弹出框正常弹出
                        if (response.config.url.includes('/login')) {
                            userAbnormal = false
                            Message({
                                message: msg || 'Error',
                                type: 'error',
                                duration: 2 * 1000,
                            })
                            return Promise.reject(response)
                        }
                        setTimeout(() => {
                            userAbnormal = false
                            Message({
                                message: msg || 'Error',
                                type: 'error',
                                duration: 2 * 1000,
                            })
                            store.dispatch('user/logout', vm)
                        }, 1000)
                    }

                    break
                default:
                    Message({
                        message: msg || 'Error',
                        type: 'error',
                        duration: 2 * 1000,
                    })
                    return Promise.reject(response)
            }
        }
    },
    (error) => {
        // TODO: 超时暂未处理
        console.log('接口连接失败', error.response)
        const err = error.response
        if (err && err.status) {
            switch (error.response.status) {
                // 401: 未登录
                // 未登录则跳转登录页面，并携带当前页面的路径
                // 在登录成功后返回当前页面，这一步需要在登录页操作。
                case 401:
                    router.replace({
                        path: '/login',
                        query: {
                            redirect: router.currentRoute.fullPath,
                        },
                    })
                    break
                case 403:
                    console.log('403')
                    Message({
                        message: '暂无权限访问',
                        type: 'error',
                        duration: 5 * 1000,
                    })
                    break

                // 404请求不存在
                case 404:
                    // router.push('/404')
                    Message({
                        message: '请求地址不存在',
                        type: 'error',
                        duration: 5 * 1000,
                    })
                    break
                // 500服务端错误
                case 500:
                    console.log('500')
                    break
                // 其他错误，直接抛出错误提示
                default:
                    Message({
                        message: error.message,
                        type: 'error',
                        duration: 5 * 1000,
                    })
            }
            return Promise.reject(error.response)
        }
        Message({
            message: '服务器未响应',
            type: 'error',
            duration: 5 * 1000,
        })
        return Promise.reject(error.response)
    }
)

export default service
