import { Message } from 'element-ui'
import vm from '../main'

export default [
	{
		directiveName: 'enterMoney',
		directiveObject: {
			inserted: function(el) {
				el.addEventListener('keypress', function(e) {
					e = e || window.event
					console.log(e)
					const charcode =
						typeof e.charCode === 'number' ? e.charCode : e.keyCode
					const re = /\d/
					if (charcode === 46) {
						// el.children[0].value是配合了el-input输入框使用的，使用input输入框时直接用el.value
						// 只能输入一个小数点，超过一个则禁止输入
						if (el.children[0].value.includes('.')) {
							e.preventDefault()
						}
						return
					} else if (
						!re.test(String.fromCharCode(charcode)) &&
						charcode > 9 &&
						!e.ctrlKey
					) {
						if (e.preventDefault) {
							e.preventDefault()
						} else {
							e.returnValue = false
						}
					}
				})
			},
		},
	},
	{
		directiveName: 'positiveNumber',
		directiveObject: {
			inserted: function(el) {
				el.addEventListener('keypress', function(e) {
					e = e || window.event
					let charcode =
						typeof e.charCode == 'number' ? e.charCode : e.keyCode
					let reg = null
					if (e.target.value.length === 0) {
						reg = /^[1-9]$/
					} else {
						reg = /\d/
					}
					if (
						!reg.test(String.fromCharCode(charcode)) &&
						charcode > 9 &&
						!e.ctrlKey
					) {
						if (e.preventDefault) {
							e.preventDefault()
						} else {
							e.returnValue = false
						}
					}
				})
				el.addEventListener('input', function(e) {})
			},
		},
	},
	{
		directiveName: 'positiveInteger',
		directiveObject: {
			inserted: function(el) {
				el.addEventListener('keypress', function(e) {
					e = e || window.event
					let charcode =
						typeof e.charCode == 'number' ? e.charCode : e.keyCode
					let reg = null
					if (e.target.value.length === 0) {
						reg = /^[0-9]$/
					} else {
						reg = /\d/
					}
					if (
						!reg.test(String.fromCharCode(charcode)) &&
						charcode > 9 &&
						!e.ctrlKey
					) {
						if (e.preventDefault) {
							e.preventDefault()
						} else {
							e.returnValue = false
						}
					}
				})
				el.addEventListener('input', function(e) {})
			},
		},
	},
	{
		directiveName: 'phoneOnly',
		directiveObject: {
			bind: function(el) {
				//  /^1[3-9][0-9]{9}$/
				el.addEventListener('keypress', function(e) {
					e.target.value = e.target.value.replace(/^0|[^\d]/g, '')
					e.target.value = e.target.value.substring(0, 11)
					if (e.target.value.length >= 11) {
						if (e.preventDefault) {
							e.preventDefault()
						} else {
							e.returnValue = false
						}
					}
				})
			},
		},
	},
	{
		directiveName: 'idOnly',
		directiveObject: {
			bind: function(el) {
				const reg = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/
				el.addEventListener('input', function(e) {
					if (e.target.value.length < 18) {
						e.target.value = e.target.value.replace(/[^\d]/g, '')
					}
					if (e.target.value.length === 18) {
						if (!reg.test(e.target.value)) {
							Message({
								message: '不是正确的身份证号码',
								type: 'error',
								duration: 1000,
							})
						}
					}
					if (e.target.value.length >= 18) {
						e.target.value = e.target.value.substring(0, 18)
					}
				})
			},
		},
	},
	// 判断是否拥有权限
	{
		directiveName: 'hasPermission',
		directiveObject: {
			inserted: function(el, binding, vnode) {
				console.log(process.env.VUE_APP_ACCOUNT_SWITCH)
				if (process.env.VUE_APP_ACCOUNT_SWITCH == 'ON') {
					// 获取权限
					let permissionList = JSON.parse(
						localStorage.getItem('permission_list')
					)[vm.$route.path.split('/')[1]]
					switch (binding.value.type) {
						case 'menu':
							let hasMenu = permissionList.some((item) => {
								return item.menuCode === binding.value.code
							})
							if (!hasMenu) {
								el.parentNode.removeChild(el)
							}
							break
						case 'button':
							let menu = {}
							if (binding.value.currenMenuCode) {
								menu = permissionList.find((item) => {
									return (
										item.menuCode ===
										binding.value.currenMenuCode
									)
								})
							} else {
								menu = permissionList.find((item) => {
									return (
										item.menuCode ===
										vm.$route.path.split('/')[2]
									)
								})
							}

							let hasButton = menu.buttons.find((button) => {
								return button.buttonCode === binding.value.code
							})
							if (!hasButton) {
								el.parentNode.removeChild(el)
							}
							break
						default:
							break
					}
				}
			},
		},
	},
]
