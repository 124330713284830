const client = {
	getClient: {
		name: '获取客户分页列表',
		type: 'get',
		path: '/customer/page',
	},
	getClientDetail: {
		name: '获取客户详情',
		type: 'get',
		path: '/customer/detail',
	},
	updataClient: {
		name: '修改客户',
		type: 'put',
		path: '/customer/update',
	},
	customerIntention: {
		name: '查看意向客户分页数据',
		type: 'get',
		path: '/customer/intention',
	},
	deleteCustomerIntention: {
		name: '删除意向客户',
		type: 'delete',
		path: '/customer/intention',
		paramType: 'URL',
	},
	customerUserInit: {
		name: '初始化客户账号密码',
		type: 'put',
		path: '/customer/user/init',
		paramType: 'URL',
	},
	customerBindPhone: {
		name: '修改绑定手机号',
		type: 'put',
		path: '/customer/bind/phone',
		paramType: 'URL',
	},
	customerSearch: {
		name: '查询客户配置',
		type: 'get',
		path: '/conf/info',
	},
	customerConf: {
		name: '新增客户配置',
		type: 'post',
		path: '/conf',
	},
	customerConfUpdate: {
		name: '修改客户配置',
		type: 'put',
		path: '/conf',
	},
}

export default client
