import Vue from 'vue'

import 'normalize.css/normalize.css' // A modern alternative to CSS resets

import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import locale from 'element-ui/lib/locale/lang/en' // lang i18n

import '@/styles/index.scss' // global css
import BaiduMap from 'vue-baidu-map'
import App from './App'
import store from './store'
import router from './router'
import adaptive from '@/components/DirectiveTable' // 表格自适应高度
import '@/icons' // icon
import '@/permission' // permission control
import api from './api'
import moment from 'moment'
import * as filters from '@/utils/filters'
import directives from '@/utils/directive'
import AddedPage from '@/components/added-page/added-page.vue'
import modelTable from '@/components/model-table/index.vue'
import config from './static/config'
import heraip from 'heraip'
console.log(heraip)
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online ! ! !
 */
// if (process.env.NODE_ENV === 'production') {
//   const { mockXHR } = require('../mock')
//   mockXHR()
// }

// set ElementUI lang to EN
// Vue.use(ElementUI, { locale })
// 如果想要中文版 element-ui，按如下方式声明
Vue.use(ElementUI)
Vue.use(heraip)

Vue.use(BaiduMap, {
	ak: 'QUzgbGeeQBZe4TKOGqK4qgg515jbFsUv',
})
Vue.directive('adaptive', adaptive)
directives.forEach((directive) => {
	Vue.directive(directive.directiveName, directive.directiveObject)
})
Vue.config.productionTip = false
Vue.component('AddedPage', AddedPage)
Vue.component('modelTable', modelTable)
Vue.prototype.$api = api
Vue.prototype.$moment = moment
Vue.prototype.moment = moment
Vue.prototype.uploadURL = config.resUrl + 'api/file/upload/'
Vue.prototype.$imageURL = config.imageURL
Vue.prototype.trunkTypeList = [
	{ name: '平台', label: 1, code: '' },
	{ name: '工厂端', label: 2, code: 'clientlist/factory' },
	{ name: '品牌商', label: 4, code: 'clientlist/brand' },
	{ name: '渠道商', label: 8, code: 'clientlist/agency' },
	{ name: '门店', label: 16, code: 'clientlist/store' },
	{ name: '顾客端', label: 32, visible: true, code: '' },
]
Vue.prototype.trunkTypeIndex = 0
Vue.prototype.unlockTypeList = [
	{ label: '支付解锁', value: 1 },
	{ label: '余额解锁', value: 2 },
	{ label: '套盒解锁', value: 3 },
	{ label: '无锁使用', value: 0 },
]
Object.keys(filters.default).forEach((key) => {
	Vue.filter(key, filters.default[key]) // 插入过滤器名和对应方法
})
const vm = new Vue({
	el: '#app',
	router,
	store,
	render: (h) => h(App),
})
export default vm
