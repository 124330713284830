import { resetRouter, dynamicRoutes } from '@/router'

import Layout from '@/layout'

import vm from '../../main'
const getDefaultState = () => {
	return {
		manager_token: '',
		name: '',
		avatar: '',
		companyId: '',
		trunkId: '',
		trunkType: '',
		loginType: '',
		leftMenuList: [],
		menuTabs: [],
		activeMenuTab: '1',
	}
}

const state = getDefaultState()

const mutations = {
	SET_ACTIVE_MENU_TAB(state, data) {
		state.activeMenuTab = data
	},
	SET_MENU_TABS(state, data) {
		state.menuTabs = data
	},
	SET_LEFT_MENU_LIST(state, data) {
		state.leftMenuList = data
	},
	SET_LOGIN_TYPE(state, data) {
		state.loginType = data
	},
	RESET_STATE: (state) => {
		Object.assign(state, getDefaultState())
	},
	SET_TOKEN: (state, token) => {
		state.manager_token = token
	},
	SET_NAME: (state, name) => {
		state.name = name
	},
	SET_AVATAR: (state, avatar) => {
		state.avatar = avatar
	},
	SET_COMPANYID: (state, companyId) => {
		state.companyId = companyId
	},
	SET_TRUKID: (state, trunkId) => {
		state.trunkId = trunkId
	},
	SET_TRUKTYPE: (state, trunkType) => {
		state.trunkType = trunkType
	},
}

const actions = {
	// user avatar
	avatar({ commit }, avatar) {
		commit('SET_AVATAR', avatar)
	},
	// user login
	login({ dispatch, commit }, data) {
		console.log(data)
		return new Promise((resolve, reject) => {
			vm.$api.user
				.login(data)
				.then((res) => {
					commit('SET_TOKEN', JSON.parse(res).token)
					// dispatch('getInfo')
					dispatch('getUserPermission')
					resolve()
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	// get user info
	getInfo({ commit, state }) {
		console.log('getinfo')

		return new Promise((resolve, reject) => {
			vm.$api.user
				.userInfo()
				.then((response) => {
					commit('SET_AVATAR', response.imagePath)
					commit('SET_NAME', response.realName)
					commit('SET_COMPANYID', response.trunkInfo.companyId)
					commit('SET_TRUKID', response.trunkInfo.trunkId)
					commit('SET_TRUKTYPE', response.trunkInfo.trunkType)
					commit('app/MODEL_LOGIN', false, { root: true })
					if (state.leftMenuList.length > 0) {
						vm.$router.push(state.leftMenuList[0].path)
					} else {
						vm.$router.push('/home')
					}

					resolve()
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	// 获取用户权限
	getUserPermission({ dispatch, commit, state }, path) {
		console.log(
			'getUserPermission------------------------------------------'
		)
		return new Promise((resolve, reject) => {
			vm.$api.user
				.accountPermission()
				.then((res) => {
					console.log(res)
					let permissionListMap = {}
					res.forEach((item) => {
						permissionListMap[item.applicationCode] =
							item.resourceMenuList
					})
					localStorage.setItem(
						'permission_list',
						JSON.stringify(permissionListMap)
					)
					// localStorage.setItem(
					// 	'permission_list_timestamp',
					// 	new Date().getTime()
					// )
					dispatch('getInfo')
				})
				.catch((error) => {
					reject(error)
				})
		})
	},
	// 清空用户权限
	clearUserPermission({ commit }) {
		commit('SET_LEFT_MENU_LIST', [])
	},
	// user logout
	logout({ commit, state }, Vue) {
		return new Promise((resolve, reject) => {
			Vue.$api.user
				.logout()
				.then(() => {
					resetRouter()
					commit('SET_AVATAR', '')
					commit('SET_NAME', '')
					commit('SET_COMPANYID', '')
					commit('SET_TRUKID', '')
					commit('SET_TRUKTYPE', '')
					commit('RESET_STATE')
					commit('SET_TOKEN', '')
					commit('app/SET_INIT_PERMISSION', false, { root: true })
					Vue.$router.push(`/login`)
					resolve()
				})
				.catch((error) => {
					reject(error)
				})
		})
	},

	// remove token
	resetToken({ commit }) {
		return new Promise((resolve) => {
			removeToken() // must remove  token  first
			commit('RESET_STATE')
			resolve()
		})
	},
}

export default {
	namespaced: true,
	state,
	mutations,
	actions,
}
