import config from '@/static/config'
const employee = {
    employeeStatusUpdate: {
        name: ' 修改账号状态',
        type: 'put',
        path: '/employee/update/status',
        paramType: 'URL',
    },
    employeeUserInit: {
        name: ' 修改员工密码',
        type: 'put',
        path: '/employee/user/init',
        paramType: 'URL',
    },
    employeeBindPhone: {
        name: ' 修改员工绑定手机号',
        type: 'put',
        path: '/employee/bind/phone',
        paramType: 'URL',
    },
}
export default employee
