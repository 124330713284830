<template>
  <el-breadcrumb class="app-breadcrumb" separator-class="el-icon-arrow-right">
    <transition-group name="breadcrumb">
      <el-breadcrumb-item v-for="(item,index) in levelList" :key="item.path">
        <a v-if="item.redirect==='noRedirect'||index==levelList.length-1" class="no-redirect"
          @click.prevent="handleRefresh">{{ item.meta.title }}</a>
        <a v-else @click.prevent="handleLink(item)">{{ item.meta.title }}</a>
      </el-breadcrumb-item>
    </transition-group>
  </el-breadcrumb>
</template>

<script>
import pathToRegexp from 'path-to-regexp'

export default {
    data() {
        return {
            levelList: null,
        }
    },
    watch: {
        $route() {
            this.getBreadcrumb()
        },
    },
    created() {
        this.getBreadcrumb()
    },
    methods: {
        handleRefresh() {
            console.log('111111111111111111')
            this.$router.go(0)
        },
        getBreadcrumb() {
            // only show routes with meta.title
            let matched = this.$route.matched.filter(
                (item) => item.meta && item.meta.title
            )
            const first = matched[0]

            if (!this.isDashboard(first)) {
                matched = [
                    { path: '/dashboard', meta: { title: '首页' } },
                ].concat(matched)
            }

            this.levelList = matched.filter(
                (item) =>
                    item.meta &&
                    item.meta.title &&
                    item.meta.breadcrumb !== false
            )
        },
        isDashboard(route) {
            const name = route && route.name
            if (!name) {
                return false
            }
            return (
                name.trim().toLocaleLowerCase() ===
                'Dashboard'.toLocaleLowerCase()
            )
        },
        pathCompile(path) {
            // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
            const { params } = this.$route
            var toPath = pathToRegexp.compile(path)
            return toPath(params)
        },
        handleLink(item) {
            const { redirect, path } = item
            if (redirect) {
                this.$router.push(redirect)
                return
            }
            this.$router.push(this.pathCompile(path))
        },
    },
}
</script>

<style lang="scss" scoped>
.app-breadcrumb.el-breadcrumb {
    display: inline-block;
    font-size: 14px;
    line-height: 50px;
    margin-left: 8px;

    .no-redirect {
        color: #97a8be;
        cursor: pointer;
    }
    .no-redirect:hover {
        color: #5496ed;
        cursor: pointer;
    }
}
</style>
