import request from './request'
import apis from './modules/index'
import config from '@/static/config'
const modules = {
    ...apis,
}
console.log(modules)
console.log(config.baseUrl)
const api = (type, url, proxy, paramType, params = {}, options = {}) => {
    if (type === 'get') {
        return request({
            url,
            baseURL: proxy === 'proxy' ? '' : config.baseUrl,
            method: 'get',
            params,
            ...options,
        })
    } else if (type === 'post') {
        if (paramType === 'URL') {
            return request({
                url,
                baseURL: proxy === 'proxy' ? '' : config.baseUrl,
                method: 'post',
                params,
                ...options,
            })
        }
        return request({
            url,
            baseURL: proxy === 'proxy' ? '' : config.baseUrl,
            method: 'post',
            data: params,
            ...options,
        })
    } else if (type === 'put') {
        if (paramType === 'URL') {
            return request({
                url,
                baseURL: proxy === 'proxy' ? '' : config.baseUrl,
                method: 'put',
                params,
                ...options,
            })
        }
        return request({
            url,
            baseURL: proxy === 'proxy' ? '' : config.baseUrl,
            method: 'put',
            data: params,
            ...options,
        })
    } else if (type === 'delete') {
        if (paramType === 'URL') {
            return request({
                url,
                baseURL: proxy === 'proxy' ? '' : config.baseUrl,
                method: 'delete',
                params,
                ...options,
            })
        }
        return request({
            url,
            baseURL: proxy === 'proxy' ? '' : config.baseUrl,
            method: 'delete',
            data: params,
            ...options,
        })
    }
}

const baseHost = ''

const result = {}

for (const module in modules) {
    const methods = modules[module]
    result[module] = {}
    for (const method in methods) {
        console.log(typeof methods[method])
        if (typeof methods[method] === 'object') {
            const {
                type,
                path,
                host,
                proxy,
                paramType,
                headers = {},
            } = methods[method]
            result[module][method] = (parmas = {}, options = {}) => {
                const url = (options.host || host || baseHost) + path

                return api(type, url, proxy, paramType, parmas, {
                    ...options,
                    headers: { ...headers, ...options.headers },
                    host: null,
                })
            }
        } else {
            result[module][method] = methods[method]()
        }
    }
}

export default result
