import config from '@/static/config'
const equipment = {
	brandList: {
		name: '获取品牌',
		type: 'get',
		path: '/trunk/merchant/list',
	},
	categoryList: {
		name: '获取产品分类',
		type: 'get',
		path: '/brand/category/list',
	},
	productList: {
		name: '产品查询',
		type: 'get',
		path: '/brand/product/list',
	},
	searchBrand: {
		name: '搜索品牌商',
		type: 'get',
		path: '/trunk/search/page',
	},
	addCheckParts: {
		name: '新增部位',
		type: 'post',
		path: '/device/check/parts/add',
	},
	partList: {
		name: '查询部位列表',
		type: 'get',
		path: '/device/check/parts/list',
	},
	updateCheckParts: {
		name: '修改部位',
		type: 'put',
		path: '/device/check/parts/update',
	},
	addCheckCheckItem: {
		name: '新增检测项目',
		type: 'post',
		path: '/device/check/add',
	},
	updateCheckItem: {
		name: '修改检测项目',
		type: 'put',
		path: '/device/check/update',
	},
	checkItemList: {
		name: '查询检测项目列表',
		type: 'get',
		path: '/device/check/list',
	},
	deleteCheck: {
		name: '删除检测项目',
		type: 'delete',
		path: '/device/check/delete',
		paramType: 'URL',
	},
	partCheckList: {
		name: '部位检测项目组合构建数据源',
		type: 'get',
		path: '/device/check/list/map',
	},
	deviceModelDetail: {
		name: '设备型号查询',
		type: 'get',
		path: '/device/model/detail',
	},
	searchDeviceModel: {
		name: '获取设备型号',
		type: 'get',
		path: '/device/model/search',
	},
	deviceModelList: {
		name: '设备型号列表',
		type: 'get',
		path: '/device/model/list/brand',
	},
	deviceDetail: {
		name: '设备详情',
		type: 'get',
		path: '/device/detail',
	},
	devicePay: {
		name: '查询支付收款账户',
		type: 'get',
		path: '/device/pay',
	},
	deviceUpgradeList: {
		name: '查询设备应用升级列表',
		type: 'get',
		path: '/device/upgrade/search',
	},
	deviceUpgradeAdd: {
		name: '新增版本',
		type: 'post',
		path: '/device/upgrade/add',
	},
	deviceUpgradeUpdate: {
		name: '修改版本',
		type: 'post',
		path: '/device/upgrade/update',
	},
	deviceUpgradeDelete: {
		name: '删除版本',
		type: 'delete',
		path: '/device/upgrade/delete',
		paramType: 'URL',
	},
	deviceUpgradePublish: {
		name: '发布版本',
		type: 'put',
		path: '/device/upgrade/publish',
		paramType: 'URL',
	},
	deviceUpgradeHistory: {
		name: '升级记录统计',
		type: 'get',
		path: '/device/upgrade/history',
	},
	deviceUpgradeHistoryLog: {
		name: '升级记录列表',
		type: 'get',
		path: '/device/upgrade/history/log',
	},
	deviceCommandList: {
		name: '设备指令列表',
		type: 'get',
		path: '/device/command/page',
	},
	deviceCommandModelPage: {
		name: '查询型号绑定指令',
		type: 'get',
		path: '/device/command/model/page',
	},
	deviceExecute: {
		name: '执行设备指令',
		type: 'post',
		path: '/device/execute',
	},
	deviceStagesList: {
		name: '查询设备流动轨迹',
		type: 'get',
		path: '/device/stages/list',
	},
	deviceSearch: {
		name: '查询设备列表',
		type: 'get',
		path: '/device/search',
	},
	deviceCommandDelete: {
		name: '删除设备指令',
		type: 'delete',
		path: '/device/command/delete',
		paramType: 'URL',
	},
	deviceModelDelete: {
		name: '删除设备型号',
		type: 'delete',
		path: '/device/model/delete',
		paramType: 'URL',
	},
	deviceClassList: {
		name: '查询设备分类',
		type: 'get',
		path: '/device/class/list/all',
	},
	deviceDelete: {
		name: '删除设备',
		type: 'delete',
		path: '/device/delete',
		paramType: 'URL',
	},
	storeSearch: {
		name: '查询品牌商关联的门店',
		type: 'get',
		path: '/brand/merchant/store/page',
	},
	deviceUseLog: {
		name: '查询设备使用记录',
		type: 'get',
		path: '/device/use/log/search',
	},
	deviceUseLogInfo: {
		name: '查询设备使用信息',
		type: 'get',
		path: config.lwUrl + 'device/use/log/info',
	},
	deviceUpgradeLog: {
		name: '查询设备版本日志',
		type: 'get',
		path: '/device/upgrade/log',
	},
	searchTrunkStore: {
		name: '搜寻主店列表',
		type: 'get',
		path: '/trunk/search/store/major/page',
	},
	propertyList: {
		name: '查询属性列表',
		type: 'get',
		path: 'property/list',
	},
	propertyPage: {
		name: '查询属性列表分页',
		type: 'get',
		path: 'property/page',
	},
	propertyAdd: {
		name: '新增属性',
		type: 'post',
		path: 'property/save',
	},
	propertyDelete: {
		name: '删除属性',
		type: 'delete',
		path: 'property/del',
		paramType: 'URL',
	},
	propertyUpdate: {
		name: '修改属性',
		type: 'post',
		path: 'property/save',
	},
	propertyClassList: {
		name: '查询属性部位单位规格列表',
		type: 'get',
		path: '/property/class/list',
	},
	propertyClassSave: {
		name: '新增属性部位单位规格',
		type: 'post',
		path: '/property/class/save',
	},
	propertyClassDelete: {
		name: '删除属性部位单位规格',
		type: 'delete',
		path: '/property/class/del',
		paramType: 'URL',
	},
	propertyClassMap: {
		name: '查询属性部位单位规格key-value',
		type: 'get',
		path: '/property/class/map',
	},

	/**
	 * 搜索
	 * @param {*} name 名称
	 * @param {*} trunkType 类型   1 系统管理 ｜ 2:工厂 ｜ 4: 品牌商 ｜ 8:经销商 ｜ 16: 门店 ｜ 32：C端｜
	 * @returns list
	 */
	search: {
		name: '搜索',
		type: 'get',
		path: '/trunk/search/page',
	},
}

export default equipment
