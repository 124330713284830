import Cookies from 'js-cookie'
import vm from '../../main'

const state = {
    sidebar: {
        opened: Cookies.get('sidebarStatus')
            ? !!+Cookies.get('sidebarStatus')
            : true,
        withoutAnimation: false,
    },
    device: 'desktop',
    modelLogin: false,
    init_permission: false, //权限初始化
}

const mutations = {
    SET_INIT_PERMISSION(state, status) {
        state.init_permission = status
    },
    TOGGLE_SIDEBAR: (state) => {
        state.sidebar.opened = !state.sidebar.opened
        state.sidebar.withoutAnimation = false
        if (state.sidebar.opened) {
            Cookies.set('sidebarStatus', 1)
        } else {
            Cookies.set('sidebarStatus', 0)
        }
    },
    CLOSE_SIDEBAR: (state, withoutAnimation) => {
        Cookies.set('sidebarStatus', 0)
        state.sidebar.opened = false
        state.sidebar.withoutAnimation = withoutAnimation
    },
    TOGGLE_DEVICE: (state, device) => {
        state.device = device
    },
    MODEL_LOGIN: (state, Status) => {
        state.modelLogin = Status
    },
}

const actions = {
    toggleSideBar({ commit }) {
        commit('TOGGLE_SIDEBAR')
    },
    closeSideBar({ commit }, { withoutAnimation }) {
        commit('CLOSE_SIDEBAR', withoutAnimation)
    },
    toggleDevice({ commit }, device) {
        commit('TOGGLE_DEVICE', device)
    },
    setModelLogin({ commit }, Status) {
        console.log(window.location.hash)
        if (window.location.hash.includes('/login')) {
            return
        }

        if (window.location.hash == '#/') {
            vm.$router.push('/login')
            return
        }
        console.log('setModelLogin')
        commit('MODEL_LOGIN', Status)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}
