import config from '@/static/config'
const user = {
	login: {
		name: ' 账号登录',
		type: 'post',
		path: config.account + '/login',
		// proxy: 'proxy',
	},
	logout: {
		name: ' 账号退出',
		type: 'post',
		path: config.account + '/user/logout',
		// proxy: 'proxy',
	},
	accountCheck: {
		name: '验证账号和手机号是否可用',
		type: 'get',
		path: config.account + '/user/account/check',
	},
	userInfo: {
		name: '获取登录用户信息',
		type: 'get',
		path: config.account + '/user/info',
	},
	checkVerification: {
		name: '验证修改密码手机号和验证码是否匹配',
		type: 'get',
		path: config.account + '/user/check/s/verification',
	},
	sseRegister: {
		name: '建立长连接',
		type: 'get',
		path: config.sseUrl + '/sse/register',
	},
	accountPermission: {
		name: '获取登录用户的权限树',
		type: 'get',
		path: config.baseUrl + 'permission/account',
	},
	accountPermissionTree: {
		name: '获取登录用户的权限树',
		type: 'get',
		path: config.baseUrl + 'permission/account/floor',
	},
}
export default user
