/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
	const valid_map = ['admin', 'editor']
	return valid_map.indexOf(str.trim()) >= 0
}
// 检验账号
export const accountCheck = (rule, value, callback, vm, trunkType) => {
	vm.$api.user
		.accountCheck({
			account: value,
			trunkType:
				trunkType ||
				vm.trunkType ||
				(vm.formInline && vm.formInline.trunkType),
		})
		.then((res) => {
			callback()
		})
		.catch((result) => {
			if (!result) {
				callback(new Error('此账号已存在不可以使用'))
			}
		})
}
// 检测手机号是否唯一
export const phoneCheck = (rule, value, callback, vm, trunkType) => {
	vm.$api.user
		.accountCheck({
			// account: vm.formInline.account,
			bindPhone: value,
			trunkType:
				trunkType ||
				vm.trunkType ||
				(vm.formInline && vm.formInline.trunkType),
		})
		.then((res) => {
			callback()
		})
		.catch((result) => {
			if (!result) {
				callback(new Error('此手机号已存在不可以使用'))
			}
		})
}
// 检验证件号码是否输入正确
export const checkIdCard = (rule, value, callback, vm) => {
	if (!value) {
		callback(new Error('请输入' + rule.name))
	} else {
		const reg = /(^(?:(?![IOZSV])[\dA-Z]){2}\d{6}(?:(?![IOZSV])[\dA-Z]){10}$)|(^\d{15}$)/
		const reg2 = /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
		if (vm.formInline.cardType === '1') {
			if (reg.test(value)) {
				callback()
			} else {
				return callback(new Error('请输入正确的' + rule.name))
			}
		} else if (vm.formInline.cardType === '0') {
			if (reg2.test(value)) {
				callback()
			} else {
				return callback(new Error('请输入正确的' + rule.name))
			}
		}
	}
}
// 检验手机号是否输入正确
export const phoneRules = (rule, value, callback) => {
	if (!value) {
		callback(new Error('请输入' + rule.name))
	} else {
		const reg = /^1[2-9][0-9]\d{8}$/
		console.log(reg.test(value))
		if (reg.test(value)) {
			callback()
		} else {
			return callback(new Error('请输入正确的' + rule.name))
		}
	}
}
// 检验输入必须是正整数
export const checkPositiveInteger = (rule, value, callback) => {
	const reg = /^[1-9]+[0-9]*[0-9]*$/
	if (reg.test(value)) {
		callback()
	} else {
		callback(new Error('请输入大于0的整数'))
	}
}
// 校验输入必须是正数
export const checkPositiveNumber = (rule, value, callback) => {
	const reg = /^[+]{0,1}[1-9]\d*$|^[+]{0,1}(0\.\d*[1-9])$|^[+]{0,1}([1-9]\d*\.\d*[1-9])$/
	if (reg.test(value) || parseFloat(value) > 0.01) {
		callback()
	} else {
		callback(new Error('请输入大于0的数值'))
	}
}
