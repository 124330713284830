import config from '@/static/config'
const notification = {
    addPrintConfig: {
        name: '新增打印配置',
        type: 'post',
        path: '/product/print/add',
    },
    updatePrintConfig: {
        name: '修改打印配置',
        type: 'put',
        path: '/product/print/update',
    },
    searchPrintConfig: {
        name: '查询打印配置',
        type: 'get',
        path: '/product/print/search',
    },
    searchTaskPrint: {
        name: '查询打印任务',
        type: 'get',
        path: 'task/print/search',
    },
    updateTaskPrint: {
        name: '修改打印任务状态',
        type: 'put',
        path: 'task/print/status',
        paramType: 'URL',
    },
    downFile: () => {
        return {
            name: '下载打印列表',
            type: 'get',
            path: config.resUrl + 'api/file/download',
        }
    },
}

export default notification
